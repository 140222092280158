/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Paper, Grid } from '@mui/material';
import { AldgQuickAdd, InputAutocomplete, InputText } from '@aldridge/aldg-data-components';
import { existsWithLength, useFunctionCall } from '@aldridge/aldg-helpers';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import ErrorBoundary from '../_GlobalComponents/ErrorBoundary';
import PageHeader from '../Components/UIComponents/PageHeader';

const JobMapping = () => {
  const [JobMappings, setJobMappings] = useState([]);
  const apiRef = useGridApiRef();
  const [currentRecord, setCurrentRecord] = useState({
    id: '',
    JobID: '',
    WipCompanyNumber: '',
    JVCompanyNumber: ''
  });

  const { data, loading, error, functionCall: WipJobMapping } = useFunctionCall(`WipJobMapping`);

  console.log(data, loading, error);
  const refreshData = () => {
    WipJobMapping({ action: 'SELECT' });
  };

  useEffect(() => {
    refreshData();
  }, []);

  // useEffect(() => {
  //   refreshData();
  // }, [currentRecord]);

  useEffect(() => {
    if (existsWithLength(data)) setJobMappings(data);
  }, [data]);

  const deleteDocument = async (row, Recordid) => {
    console.log('delete row', row, Recordid);
    // CHECK - DELETE FROM SQL
    WipJobMapping({ action: 'DELETE', values: { id: Recordid } });
    setJobMappings((prev) => prev.filter((x) => x.id !== Recordid));
    // refreshData();
  };

  const clearRecord = () => {
    setCurrentRecord((prev) => ({ ...prev, id: '', JobID: '', WipCompanyNumber: '', JVCompanyNumber: '' }));
  };

  const handleSave = () => {
    console.log(currentRecord);
    // CHECK - UPSERT INTO SQL
    if (existsWithLength(currentRecord.id)) {
      setJobMappings((prev) => prev.map((x) => (x.id === currentRecord.id ? currentRecord : x)));
      WipJobMapping({ action: 'UPDATE', values: currentRecord });
    } else {
      setJobMappings((prev) => [...prev, { ...currentRecord, id: JobMappings.length + 1 }]);
      WipJobMapping({ action: 'INSERT', values: currentRecord });
    }

    clearRecord();
    // refreshData();
  };

  const UpdateRow = (row) => {
    console.log('row', row);
    setCurrentRecord({
      id: row.id,
      JobID: row.JobID,
      WipCompanyNumber: row.WipCompanyNumber,
      JVCompanyNumber: row.JVCompanyNumber
    });
  };

  const columns = [
    {
      field: 'Job',
      inputType: 'InputAutocomplete',
      valueGetter: (params) => params.row.JobID,
      flex: 50,
      canEdit: true
      // config,
      // firestoreOptions: {
      //   valueKey: 'id',
      //   collection: 'ENT-Jobs',
      //   orderBy: 'id',
      //   method: 'get',
      //   where: [['EcmsStatusSimplified', '==', 'Open']]
      // },
      // autorecordFields: ['CosentialJobName'],
      // optionDisplay: (opt) => `${opt.id}`
    },
    {
      field: 'WIP Company Number',
      inputType: 'InputText',
      valueGetter: (params) => params.row.WipCompanyNumber,
      flex: 50,
      canEdit: true
    },
    {
      field: 'JV Company Number',
      inputType: 'InputText',
      valueGetter: (params) => params.row.JVCompanyNumber,
      flex: 50,
      canEdit: true
    },
    {
      field: 'Delete',
      headerName: '',
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <ToolTipIconButton
            shape='circle'
            tooltipText='Delete'
            color='error'
            icon={faTrash}
            onClick={() => {
              const id = params.row.id;
              deleteDocument(params.row, id);
            }}
          />
        </div>
      ),
      flex: 40
    }
  ];

  return (
    <ErrorBoundary componentName='JV WIP Job Management'>
      <Paper variant='outlined' className='aldg-field'>
        <Grid container>
          <PageHeader title='JV WIP Job Management' />
          <Grid item xs={12}>
            <AldgQuickAdd
              DataGridProps={{
                apiRef,
                pagination: true,
                getRowId: (params) => params.id,
                loading,
                onCellClick: (cell, event) => {
                  if (cell.field === 'Delete') {
                    event.preventDefault();
                    event.stopPropagation();
                  }
                }
              }}
              anchor='right'
              updateRow={UpdateRow}
              columns={columns}
              rows={JobMappings}
              handleSave={handleSave}
              handleCancel={clearRecord}
              form={
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} padding={1}>
                  <Grid item xs={12}>
                    <InputAutocomplete
                      firestoreOptions={{
                        valueKey: 'id',
                        collection: 'ENT-Jobs',
                        where: [['EcmsCompanyNumber', '!=', '1']],
                        method: 'onSnapshot'
                      }}
                      label='Job ID'
                      name='JobID'
                      onChange={(x, y, z, a) => {
                        setCurrentRecord((prev) => ({
                          ...prev,
                          JobID: `${a?.[0]?.id}`
                        }));
                      }}
                      optionDisplay={(opt) => `${opt.id}`}
                      value={currentRecord.JobID || ''}
                      disablePortal
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputText
                      label='Wip Company Number'
                      name='WipCompanyNumber'
                      value={currentRecord.WipCompanyNumber || ''}
                      onChange={(e) => setCurrentRecord((prev) => ({ ...prev, WipCompanyNumber: e }))}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputText
                      label='JV Company Number'
                      name='JVCompanyNumber'
                      value={currentRecord.JVCompanyNumber || ''}
                      onChange={(e) => setCurrentRecord((prev) => ({ ...prev, JVCompanyNumber: e }))}
                    />
                  </Grid>
                </Grid>
              }
            />
          </Grid>
        </Grid>
      </Paper>
    </ErrorBoundary>
  );
};
export default JobMapping;
